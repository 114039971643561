import { InformationCircleIcon } from '@heroicons/react/solid';
import { Popover } from '@mantine/core';
import { addDays, format } from 'date-fns';
import { useMemo, useState } from 'react';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import Select from 'react-select';
import { RRule, rrulestr } from 'rrule';
import { SaveReservationData } from '@/lib/utils-reserve';
import ReserveFieldRecurringAdvancedForm, {
  AdvancedFormState
} from '@/components/reserve/ReserveFieldRecurringAdvancedForm';
import { FormField } from '@/components/ui/form';
import { useAuthenticated } from '@/lib/api/appUser';
import { isRBFeature } from '@/lib/featureFlags';
import { byWeekDay } from './reserveRecurringUtils';

type Props = {
  defaultValue: SaveReservationData['recurring'];
  form: UseFormReturn<any>;
  errors: FieldErrors<SaveReservationData>;
};

const recurringOptionsTemplate: {
  label: string;
  value: RecurringFixedValues;
  debugRRule?: string;
}[] = [
  { label: 'Does not repeat', value: 'no' },
  { label: '', value: 'daily' },
  { label: '', value: 'weekly' },
  { label: '', value: 'monthly' },
  { label: '', value: 'annually' },
  { label: '', value: 'every-weekday' },
  { label: '', value: 'custom' }
];

const recurringOptionsTemplateOnlyCustom: {
  label: string;
  value: RecurringFixedValues;
  debugRRule?: string;
}[] = [
  { label: 'Does not repeat', value: 'no' },
  { label: 'Recurring', value: 'custom' }
];

function formatLabel(
  label: string,
  date: Date,
  state: AdvancedFormState | null,
  rule: RRule
) {
  return rule.toText();
}


type RecurringFixedValues =
  | 'no'
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'annually'
  | 'every-weekday'
  | 'custom';

function getFixedDefaultValue(
  fixed: RecurringFixedValues,
  date: Date
): {
  rule: RRule;
  label?: string;
} {
  let rule = new RRule();
  if (fixed === 'daily') {
    rule = new RRule({
      freq: RRule.DAILY,
      interval: 1,
      byweekday: [],
      dtstart: date,
      until: null
    });
    return { rule };
  // biome-ignore lint/style/noUselessElse: <it's intentional>
  } else if (fixed === 'weekly') {
    rule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      byweekday: [],
      dtstart: date,
      until: null
    });
    return { rule, label: `Weekly on ${format(date, 'iiii')}` };
  // biome-ignore lint/style/noUselessElse: <it's intentional>
  } else if (fixed === 'monthly') {
    rule = new RRule({
      freq: RRule.MONTHLY,
      interval: 1,
      byweekday: byWeekDay(date),
      dtstart: date,
      until: null
    });
    return { rule };
  // biome-ignore lint/style/noUselessElse: <it's intentional>
  } else if (fixed === 'annually') {
    rule = new RRule({
      freq: RRule.YEARLY,
      interval: 1,
      byweekday: [],
      dtstart: date,
      until: null
    });
    return { rule, label: `Annually on ${format(date, 'LLLL dd')}` };
  // biome-ignore lint/style/noUselessElse: <it's intentional>
  } else if (fixed === 'every-weekday') {
    rule = new RRule({
      freq: RRule.DAILY,
      interval: 1,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
      dtstart: date,
      until: null
    });
    return { rule, label: 'On every weekday (Monday to Friday)' };
  // biome-ignore lint/style/noUselessElse: <it's intentional>
  } else if (fixed === 'custom') {
    rule = new RRule({
      freq: RRule.DAILY,
      interval: 1,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
      dtstart: date,
      until: addDays(date, 30)
    });
  }
  return { rule };
}

export default function ReserveFieldRecurring({
  defaultValue,
  form,
  errors
}: Props) {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const isRBFlag = isRBFeature(authenticatedUser);
  const name = 'recurring';
  const [forceCustom, setForceCustom] = useState(false);
  const currentValue = form.watch(name, defaultValue);
  const parsed =
    currentValue === '' || currentValue === 'no'
      ? new RRule({
          freq: RRule.DAILY
        })
      : rrulestr(currentValue);
  const [date, ...rest] = form.watch('weekDates');
  parsed.options.dtstart = date;

  const advancedFormState: AdvancedFormState = {
    interval: parsed.options.interval ?? 1,
    freq: parsed.options.freq,
    byweekday: [
      RRule.SU,
      RRule.MO,
      RRule.TU,
      RRule.WE,
      RRule.TH,
      RRule.FR,
      RRule.SA
    ].filter(
      (x) =>
        parsed.options.byweekday &&
        parsed.options.byweekday.indexOf(x.weekday) > -1
    ),
    until: parsed.options.until
  };
  const [advanced, setAdvanced] = useState(false);

  const optionsTemplate = !isRBFlag
    ? recurringOptionsTemplate
    : recurringOptionsTemplateOnlyCustom;
  const customLabel = !isRBFlag ? ' (custom)' : ' (multiple)';

  const recurringOptions = useMemo(
    () =>
      date
        ? optionsTemplate.map((x) => {
            const defaultValue = getFixedDefaultValue(x.value, date);
            return {
              ...x,
              debugRRule: defaultValue.rule.toString(),
              label:
                date && x.value !== 'no'
                  ? defaultValue.label ??
                    formatLabel(
                      x.label,
                      date,
                      advancedFormState,
                      x.value === 'custom' ? parsed : defaultValue.rule
                    ) + (x.value === 'custom' ? customLabel : '')
                  : x.label
            };
          })
        : [],
    [date, optionsTemplate, advancedFormState, currentValue]
  );

  let currentFixedValue =
    recurringOptions.find(
      (x) =>
        x.value === currentValue ||
        getFixedDefaultValue(x.value, date).rule.toString() === currentValue
    ) ?? recurringOptions[recurringOptions.length - 1];
  const isCustom =
    recurringOptions.indexOf(currentFixedValue) ===
      recurringOptions.length - 1 || forceCustom;
  if (isCustom) {
    currentFixedValue = recurringOptions[recurringOptions.length - 1];
  }

  const setAdvancedFormState = (x: AdvancedFormState) => {
    const rrule = new RRule({
      freq: x.freq,
      interval: x.interval,
      byweekday: [...x.byweekday],
      dtstart: date,
      until: x.until
    });
    form.setValue(name, rrule.toString());
    setForceCustom(true);
  };

  if (!date || rest.length) {
    return null;
  }
  const maxDates = 10;
  const all = parsed.all((d, i) => i < maxDates + 1);

  return (
    <div className="mt-0">
      <>
        <FormField
          control={form.control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Select
              onChange={(v) => {
                field.onChange(v);
                if (v) {
                  if (v.value === 'no') {
                    form.setValue(name, 'no');
                    setForceCustom(false);
                  } else {
                    if (v.value === 'custom') {
                      setAdvanced(true);
                      setForceCustom(true);
                      form.setValue(name, parsed.toString());
                    } else {
                      form.setValue(
                        name,
                        getFixedDefaultValue(
                          v.value as RecurringFixedValues,
                          date
                        ).rule.toString()
                      );
                      setForceCustom(false);
                    }
                  }
                }
              }}
              value={currentFixedValue}
              className={'resource-select capitalize'}
              classNamePrefix="react-select"
              options={recurringOptions}
            />
          )}
        />

        {errors.recurring && errors.recurring.type === 'required' && (
          <p className="pl-1 text-primary">Required</p>
        )}
        {errors.recurring && errors.recurring.type != 'required' && (
          <p className="text-primary">
            Hmm, something went wrong. Can you try again?
          </p>
        )}

        <div className="flex items-center justify-end text-right text-sm">
          {currentValue !== '' && currentValue !== 'no' && all.length ? (
            <Popover position="top" shadow="md">
              <Popover.Target>
                <span className="flex cursor-pointer items-center space-x-1">
                  <span className="">
                    (
                    {all.length > maxDates
                      ? `+${maxDates.toString()}`
                      : all.length}{' '}
                    dates)
                  </span>
                  <InformationCircleIcon className="mt-0.5 h-5 w-5 text-primary" />
                </span>
              </Popover.Target>
              <Popover.Dropdown className="z-20">
                <ul className="text-base font-normal text-gray-800">
                  {all.map((d) => (
                    <li key={format(d, 'yyyy-MM-dd')}>
                      {format(d, 'MMM do (EEE), yyyy')}
                    </li>
                  ))}
                  {all.length > maxDates ? <li>...</li> : null}
                </ul>
              </Popover.Dropdown>
            </Popover>
          ) : null}
          {isCustom && !advanced ? (
            <button
              className="mt-1 ml-4 block"
              onClick={(ev) => {
                ev.preventDefault();
                setAdvanced(true);
              }}
            >
              Options
            </button>
          ) : null}
        </div>

        {isCustom && advanced ? (
          <ReserveFieldRecurringAdvancedForm
            state={advancedFormState}
            setState={setAdvancedFormState}
            setAdvanced={setAdvanced}
            hideWeekends={isRBFlag}
          />
        ) : null}
      </>
    </div>
  );
}
