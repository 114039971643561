import React, { ReactNode, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import { classNames } from '@/lib/classNames';
import clsx from 'clsx';

type MenuItemsProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClick?: Function;
  href?: string;
  disabled?: boolean;
  text: string;
  iconLeft?: {
    icon: IconType;
    className?: string;
  };
};

const MenuItem = ({
  onClick,
  text,
  href,
  disabled,
  iconLeft
}: MenuItemsProps) => {
  // Disabled is only a valid button state, just return nothing if the link should be disabled
  if (disabled) {
    return <></>;
  }

  return (
    <Menu.Item>
      {({ active }) => (
        <a
          className={`block px-4 py-2  text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 ${
            active && 'bg-gray-200'
          } ${iconLeft ? 'flex items-center space-x-2' : ''}`}
          role="menuitem"
          href={href}
          onClick={() => onClick && onClick()}
        >
          {iconLeft
            ? React.createElement(iconLeft.icon as IconType, {
                className: iconLeft.className || '',
                'aria-hidden': true
              })
            : ''}
          {iconLeft ? <span className="truncate">{text}</span> : text}
        </a>
      )}
    </Menu.Item>
  );
};

type IconType =
  | string
  | React.FunctionComponent<{ className: string; 'aria-hidden': boolean }>
  | React.ComponentClass<{ className: string; 'aria-hidden': boolean }>;

export interface Props {
  /**
   * Defines if the button is disabled
   */
  disabled?: boolean;
  /**
   * The size of the button
   */
  size?: 'sm' | 'base' | 'lg' | 'xl' | 'pagination' | 'custom';
  /**
   * Shows only one icon inside the button; defaults to left
   */
  icon?: IconType;
  /**
   * Shows an icon inside the button, left aligned
   */
  iconLeft?: IconType;
  /**
   * Shows an icon inside the button, right aligned
   */
  iconRight?: IconType;
  /**
   * ButtonDropdown color
   */
  variant?: 'orange' | 'blue' | 'white' | 'primary';
  /**
   * ButtonDropdown color
   */
  font?:
    | 'normalWhite'
    | 'normalGray'
    | 'boldWhite'
    | 'boldGray'
    | 'boldPrimary';
  /**
   * The style of the button
   */
  layout?: 'link' | 'rounded' | 'squared' | 'block' | '__dropdownItem';
  /**
   * Make button full width on mobile
   */
  mobileFull?: boolean;
  /**
   * Menu items
   */
  menuItems: MenuItemsProps[];
  /**
   * Menu items classname
   */
  menuItemsClassName?: string;
}

export interface ButtonDropdownAsButtonDropdownProps
  extends Props,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The element that should be rendered as a button
   */
  tag?: 'button';
  /**
   * The native HTML button type
   */
  type?: 'button' | 'submit' | 'reset';
}

export interface ButtonDropdownAsAnchorProps
  extends Props,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  tag: 'a';
}

export interface ButtonDropdownAsOtherProps
  extends Props,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  tag: string;
}

export type ButtonDropdownProps =
  | ButtonDropdownAsButtonDropdownProps
  | ButtonDropdownAsAnchorProps
  | ButtonDropdownAsOtherProps;

type Ref = ReactNode | HTMLElement | string;

const styles = Object.freeze({
  base: 'text-base shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
  font: {
    boldWhite: 'text-white font-bold',
    boldGray: 'text-gray-800 font-bold',
    normalWhite: 'text-white font-normal',
    normalGray: 'text-gray-800 font-normal',
    boldPrimary: 'text-primary-text font-bold'
  },
  size: {
    xl: 'px-10 py-4',
    lg: 'px-8 py-3',
    base: 'px-7 py-3',
    sm: 'px-3 py-1 text-sm',
    custom: '',
    icon: {
      xl: 'p-4',
      lg: 'p-3',
      base: 'p-2',
      sm: 'p-2',
      pagination: '',
      custom: ''
    },
    pagination: 'px-3 py-1 rounded-md text-xs'
  },
  variants: {
    orange: {
      base: 'bg-primary md:hover:bg-orange-600 focus-visible:ring-orange-300',
      active:
        'active:bg-primary md:hover:bg-orange-400 focus:shadow-outline-primary',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    blue: {
      base: 'bg-secondary-bg hover:bg-blue-700 focus:ring-blue-800',
      active:
        'active:bg-secondary hover:bg-secondary focus:shadow-outline-primary',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    white: {
      base: 'bg-white hover:bg-gray-50 border border-gray-300 focus:outline-none border border-gray-300',
      active:
        'active:bg-transparent hover:bg-gray-100 focus:shadow-outline-gray',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    primary: {
      base: 'bg-primary hover:bg-primary-hover',
      active:
        'active:bg-primary md:hover:bg-primary-hover focus:shadow-bg-primary',
      disabled: 'bg-disabled cursor-not-allowed'
    }
  },
  // styles applied to the SVG icon
  icon: {
    xl: 'h-5 w-5',
    lg: 'h-5 w-5',
    base: 'h-5 w-5',
    sm: 'h-3 w-3',
    left: 'mr-2 -ml-1',
    right: 'ml-2 -mr-1',
    pagination: '',
    custom: '',
  },
  rounded: 'rounded-full',
  squared: 'rounded-md',
  block: 'inline-block w-full rounded-md text-center',
  link: {
    base: 'text-gray-600 focus:outline-none border border-transparent',
    active: '',
    disabled: 'opacity-50 cursor-not-allowed'
  },
  // this is the button that lives inside the DropdownItem
  dropdownItem: {
    base: 'inline-flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800'
  }
});

export const ButtonDropdown = React.forwardRef<Ref, ButtonDropdownProps>(
  function ButtonDropdown(props, ref) {
    const {
      tag = 'button',
      // Fix https://github.com/estevanmaito/windmill-react-ui/issues/7
      type = tag === 'button' ? 'button' : undefined,
      disabled = false,
      size = 'base',
      layout = 'squared',
      icon,
      iconLeft,
      iconRight,
      variant = 'primary',
      font = 'boldPrimary',
      mobileFull,
      menuItems,
      menuItemsClassName,
      className,
      children,
      ...other
    } = props;

    function hasIcon() {
      return !!icon || !!iconLeft || !!iconRight;
    }

    const IconLeft = iconLeft || icon;
    const IconRight = iconRight;

    /**
     * Only used in DropdownItem.
     * Not meant for general use.
     */
    const dropdownItemStyle = styles.dropdownItem.base;

    const buttonStyles =
      layout === '__dropdownItem'
        ? classNames(
            dropdownItemStyle,
            className,
            'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary'
          )
        : clsx(
            styles.base,
            // Focus outline for keyboard accessibility
            'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary',
            // has icon but no children
            hasIcon() && !children && styles.size.icon[size],
            // has icon and children
            hasIcon() && children && styles.size[size],
            hasIcon() && children && styles.size[size] && ' flex items-center',
            // does not have icon
            !hasIcon() && styles.size[size],
            styles[layout],
            styles.variants[variant].base,
            styles.font[font],
            disabled
              ? styles.variants[variant].disabled
              : styles.variants[variant].active,
            mobileFull ? 'w-full md:w-auto' : '',
            className
          );

    const iconLeftStyles = classNames(
      styles.icon[size],
      children ? styles.icon.left : ''
    );
    const iconRightStyles = classNames(
      styles.icon[size],
      children ? styles.icon.right : '',
      'ml-2 mt-0.5'
    );

    return (
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className={buttonStyles}>
                {children}
                {iconRight
                  ? React.createElement(IconRight as IconType, {
                      className: iconRightStyles,
                      'aria-hidden': true
                    })
                  : ''}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className={classNames(
                  'absolute right-0 mt-2 w-40 origin-top-right cursor-pointer rounded-md border border-gray-200 bg-white shadow-lg outline-none md:w-56 z-10',
                  menuItemsClassName ?? ''
                )}
              >
                {menuItems.map((item, index) => {
                  return (
                    <MenuItem
                      text={item.text}
                      onClick={item.onClick}
                      href={item.href}
                      disabled={item.disabled}
                      iconLeft={item.iconLeft}
                      key={index}
                    />
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    );
  }
);

export default ButtonDropdown;
