import { WorkingLocationPresenceIcon } from '@/components/dashboard/WorkingLocationPresenceIcon';
import { getLocationIdentifier } from '@/components/dashboard/WorkingLocationSelector';
import { Favs } from '@/components/favs/Favs';
import { SelectOffices } from '@/components/partial/SelectOffices';
import { SelectTeamsBeta2 } from '@/components/partial/SelectTeamsBeta2';
import { SelectUsersBeta2 } from '@/components/partial/SelectUsersBeta2';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
import {
  useAllUserSettings,
  useSaveUserSettings
} from '@/hooks/useUserSettings';
import { useWorkingLocations } from '@/hooks/useWorkingLocations';
import { useAuthenticated } from '@/lib/api/appUser';
import { classNames } from '@/lib/classNames';
import {
  // getStringifiedItem,
  // setItem
  isJSON
} from '@/lib/storage';
import { isAllowedToReserve, parseApiDate } from '@/lib/utils';
import {
  ALL_WEEK_DAYS,
  IUserFull,
  IWorkingLocation,
  IWorkingLocations,
  Office,
  ScheduleParamsAll,
  UserPropertyType
} from '@gettactic/api';
import { useWindowSize } from '@gettactic/helpers/src/hooks/useWindowSize';
import { ScrollArea } from '@mantine/core';
// import { TEAM_FILTER_STORE } from '@/lib/constants';
import { useQueryClient } from '@tanstack/react-query';
import { addDays, format, parse, startOfWeek } from 'date-fns';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { USER_PHOTO_SIZES, UserPhotoCard } from './Cards/UserPhotoCard';

const ListItemBeta2 = ({
  week,
  user,
  office,
  workingLocationData,
  withReservation
}: {
  week: Date[];
  withReservation: boolean;
  user: IUserFull;
  office: Office | undefined;
  workingLocationData: IWorkingLocations['days'];
}): JSX.Element => {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const router = useRouter();
  const officesById = authenticatedUser?.offices?.byId ?? [];

  const showRow = useMemo(
    () =>
      !withReservation ||
      ALL_WEEK_DAYS.some((_, dayNumber) => {
        const date = Object.keys(workingLocationData)[dayNumber];
        const userWorkingLocation: IWorkingLocation | null =
          workingLocationData[date]
            ? workingLocationData[date].find(
                (x: IWorkingLocation) => x.user.id === user.id
              ) ?? null
            : null;
        return !!userWorkingLocation?.resource;
      }),
    [workingLocationData, withReservation]
  );

  return !showRow ? (
    <></>
  ) : (
    <li className="border-b border-gray-200" key={user.id}>
      <div
        className="grid grid-cols-12 items-center py-4 gap-4 \
        lg:gap-0
      "
      >
        <Link className="cursor-pointer" href={`/directory/${user.id}`}>
          <div
            className="col-span-12 flex min-w-0 flex-1 items-center \
          cursor-pointer lg:col-span-6"
          >
            <div className="flex-shrink-0">
              <UserPhotoCard
                size={USER_PHOTO_SIZES.ROUNDED_12_12}
                name={user.name}
                profile_photo_url={
                  user.profile_photo_url ? user.profile_photo_url : null
                }
              />
            </div>
            <div className="min-w-0 flex-1 px-4">
              <div className="flex flex-col truncate text-base text-tertiary">
                <p className="flex items-center space-x-1 font-semibold leading-5">
                  <span className="quicksand text-xl">{user.name}</span>
                  <Favs size="sm" user={user} />
                </p>
                {!!user.title && <p className="quicksand">{user.title}</p>}
              </div>
            </div>
          </div>
        </Link>
        <div
          className="col-span-12 grid grid-cols-week text-center \
        lg:col-span-6 lg:flex lg:justify-end lg:gap-6"
        >
          {week.map((date) => {
            const dateStr = format(date, 'yyyy-MM-dd');
            // Checks the array of objects for date matches
            // if it finds a match, sets styling for active
            const startDate = parseApiDate(dateStr);
            const allowed = isAllowedToReserve(
              startDate,
              office?.time_zone ?? '',
              office,
              'workspace'
            );
            const userWorkingLocation: IWorkingLocation | null =
              workingLocationData[dateStr]
                ? workingLocationData[dateStr].find(
                    (x: IWorkingLocation) => x.user.id === user.id
                  ) ?? null
                : null;

            // This is needed since the remote policy somehow gets classified as "out of office"
            if (
              userWorkingLocation?.location === 'out_of_office' &&
              userWorkingLocation?.policy_type === 'remote'
            ) {
              userWorkingLocation.location = 'remote';
            }

            const hasDesk = !!userWorkingLocation?.resource;
            const locationOffice : Office = //@ts-ignore
              officesById[userWorkingLocation?.resource?.office_id ?? ''] ??
              userWorkingLocation?.office;
            const goToMap = () => {
              if (!hasDesk) return;
              // TODO: Probably we should forward schedule hours,
              //  but we don't have direct access to the schedule.id
              router.push(
                `/map#resource=${userWorkingLocation?.resource?.id}&office=${userWorkingLocation?.resource?.office_id}&date=${dateStr}`
              );
            };

            return (
              <div key={dateStr}>
                <div className="block lg:hidden">
                  {/* Show week abbrevation */}
                  <span className="quicksand font-medium">
                    {format(date, 'EEEEE')}
                  </span>
                </div>
                <div
                  aria-label={dateStr}
                  className={classNames(
                    hasDesk ? 'cursor-pointer' : 'cursor-default',
                    !allowed && '',
                    hasDesk ? 'text-primary' : 'text-disabled'
                  )}
                  onClick={goToMap}
                >
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger>
                        <WorkingLocationPresenceIcon
                          workingLocationId={getLocationIdentifier(
                            userWorkingLocation
                          )}
                          fullLocation={
                            { office: locationOffice } as IWorkingLocation
                          }
                          className="h-5 w-5"
                        />
                      </TooltipTrigger>
                      {(locationOffice || userWorkingLocation?.location) && (
                        <TooltipContent>
                          <p>
                            {hasDesk && locationOffice
                              ? `${locationOffice.name} - ${userWorkingLocation?.resource?.name}`
                              : locationOffice
                                ? locationOffice.name
                                : ''}
                            {userWorkingLocation?.location === 'remote'
                              ? 'Remote'
                              : ''}
                            {userWorkingLocation?.location ===
                              'out_of_office' &&
                            userWorkingLocation?.policy_type != 'remote'
                              ? 'OOO'
                              : ''}
                            {userWorkingLocation?.location === 'not_available'
                              ? 'N/A'
                              : ''}
                          </p>
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </li>
  );
};

function collectUsersFromWL(wl: IWorkingLocations['days']) {
  const users: IUserFull[] = [];
  Object.keys(wl).forEach((e) => {
    wl[e].forEach((w) => {
      if (!users.find((x) => x.id === w.user.id)) {
        users.push(w.user);
      }
    });
  });
  return users.sort((a, b) => a.name.localeCompare(b.name));
}

const CheckedInTeamMembersBeta2 = ({ weekDate }: { weekDate: string }) => {
  const {
    userContext: { authenticatedUser, currentOffice }
  } = useAuthenticated();
  const queryClient = useQueryClient();
  const lastGroupUsed =
    authenticatedUser?.user?.organization_user.last_team_group_id;
  const [withReservation, setWithReservation] = useState(false);
  const { userSettings } = useAllUserSettings();
  const saveUserSettings = useSaveUserSettings(queryClient);
  const [usersFilter, setUsersFilter] = useState<string[]>([]);
  const [teams, setTeams] = useState<string[]>([]);
  const [officesIds, setOfficesIds] = useState<string[]>([]);
  const parsedDate = parse(weekDate, 'yyyy-MM-dd', new Date());
  const firstMondayBefore = startOfWeek(parsedDate, { weekStartsOn: 1 });

  const hideWeekends =
    authenticatedUser.organization?.features?.includes('hide.weekends') ??
    false;
  // @todo: This is a hack because the API is not returning the complete week
  const week = useMemo(() => {
    const week = [];
    const days = hideWeekends ? 5 : 7;
    for (let i = 0; i < days; i++) {
      week.push(addDays(firstMondayBefore, i));
    }
    return week;
  }, [weekDate]);

  const paramsWorkingLocation: ScheduleParamsAll = {
    date: format(firstMondayBefore, 'yyyy-MM-dd'),
    weeks: 1,
    team: teams,
    office: officesIds,
    user: usersFilter,
    limit: 10
  };
  const workingLocationData = useWorkingLocations(paramsWorkingLocation, {
    keepPreviousData: true,
    enabled: !!currentOffice.id
  });

  const wlAllData: IWorkingLocations['days'] = useMemo(() => {
    const wlAllData: IWorkingLocations['days'] = {};
    if (!workingLocationData?.data) return wlAllData;
    const pages = workingLocationData?.data.pages;
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      week.forEach((date) => {
        const dateStr = format(date, 'yyyy-MM-dd');
        wlAllData[dateStr] = wlAllData[dateStr] ?? [];
        if (page && page.days[dateStr]) {
          wlAllData[dateStr] = wlAllData[dateStr].concat(page.days[dateStr]);
        }
      });
    }

    return wlAllData;
  }, [workingLocationData?.data]);

  const browserWindowSize = useWindowSize();

  const sortedUsers = useMemo(() => {
    const workingLocationUsers = wlAllData ? collectUsersFromWL(wlAllData) : [];
    const filteredUsers = workingLocationUsers.filter((user) => {
      if (user.roles.includes('guest')) return false;
      if (user.email.toLowerCase().endsWith('resource.calendar.google.com'))
        return false;
      if (user.email.toLowerCase().includes('conference_room@')) return false;
      if (user.email.toLowerCase().includes('boardroom@')) return false;
      if (user.name.toLowerCase().includes('conference room')) return false;
      if (user.name.toLowerCase().includes('meeting room')) return false;
      return true;
    });
    return filteredUsers;
  }, [wlAllData]);

  const [
    officesFilterSelectedIds,
    teamsFilterSelectedIds,
    usersFilterSelectedIds
  ] = useMemo(() => {
    if (!userSettings) return [undefined, undefined, undefined];
    let officesIds: Array<string> | undefined;
    let teamsIds: Array<string> | undefined;
    let usersIds: Array<string> | undefined;
    for (const setting of userSettings) {
      if (
        setting.organization_id !== authenticatedUser.organization?.id ||
        setting.user_id !== authenticatedUser.user?.id ||
        !isJSON(setting.value)
      )
        continue;

      const parsedValue = JSON.parse(setting.value);

      if (!Array.isArray(parsedValue)) continue;

      switch (setting.type) {
        case UserPropertyType.LastOfficesFilter:
          officesIds = parsedValue as Array<string>;
          break;
        case UserPropertyType.LastTeamsFilter:
          teamsIds = parsedValue as Array<string>;
          break;
        case UserPropertyType.LastUsersFilter:
          usersIds = parsedValue as Array<string>;
          break;
      }
    }
    return [officesIds, teamsIds, usersIds];
  }, [
    authenticatedUser.organization?.id,
    authenticatedUser.user?.id,
    userSettings
  ]);

  // const officesFilterSelectedIds = getStringifiedItem(TEAM_FILTER_STORE, 'offices');
  // const teamsFilterSelectedIds = getStringifiedItem(TEAM_FILTER_STORE, 'teams');
  // const teamsFilterSelectedIds = getStringifiedItem(TEAM_FILTER_STORE, 'users');

  useEffect(() => {
    if (officesFilterSelectedIds) {
      setOfficesIds(officesFilterSelectedIds);
    } else {
      setOfficesIds(currentOffice.id ? [currentOffice.id] : []);
    }
  }, [officesFilterSelectedIds, currentOffice.id]);

  useEffect(() => {
    if (teamsFilterSelectedIds) {
      setTeams(teamsFilterSelectedIds);
    } else {
      setTeams(lastGroupUsed ? [lastGroupUsed] : []);
    }
  }, [teamsFilterSelectedIds, lastGroupUsed]);

  useEffect(() => {
    if (usersFilterSelectedIds) {
      setUsersFilter(usersFilterSelectedIds);
    } else {
      setUsersFilter([]);
    }
  }, [usersFilterSelectedIds]);

  const handleSaveFilterData = (type: UserPropertyType, value: string) => {
    saveUserSettings.mutate({
      type,
      value
    });
  };

  return (
    <div className="flex flex-col py-4">
      <div className="col-span-12">
        <div className="flex items-center flex-wrap gap-2">
          <SelectOffices
            offices={officesIds}
            applyOnClick={true}
            onChange={(ids: string[]) => {
              // setItem(TEAM_FILTER_STORE, 'offices', ids);
              handleSaveFilterData(
                UserPropertyType.LastOfficesFilter,
                JSON.stringify(ids)
              );
              setOfficesIds(ids);
            }}
            className="w-full lg:w-56"
          />
          <SelectTeamsBeta2
            includeFavs={true}
            teams={teams}
            applyOnClick={true}
            onChange={(teamIds: string[]) => {
              // setItem(TEAM_FILTER_STORE, 'teams', teamIds);
              handleSaveFilterData(
                UserPropertyType.LastTeamsFilter,
                JSON.stringify(teamIds)
              );
              setTeams(teamIds);
            }}
            className="w-full lg:w-56"
          />
          <SelectUsersBeta2
            applyOnClick={true}
            onChange={(userIds) => {
              // setItem(TEAM_FILTER_STORE, 'users', userIds);
              handleSaveFilterData(
                UserPropertyType.LastUsersFilter,
                JSON.stringify(userIds)
              );
              setUsersFilter(userIds);
            }}
            values={usersFilter}
            className="w-full lg:w-56"
          />
          {/*<Switch*/}
          {/*  color="orange"*/}
          {/*  size="md"*/}
          {/*  label="Reservation"*/}
          {/*  labelPosition="left"*/}
          {/*  checked={withReservation}*/}
          {/*  onChange={(event) => setWithReservation(event.currentTarget.checked)}*/}
          {/*/>*/}
          {/*<Checkbox*/}
          {/*  className="mt-2"*/}
          {/*  color="orange"*/}
          {/*  size="md"*/}
          {/*  label="Only with Reservation"*/}
          {/*  checked={withReservation}*/}
          {/*  onChange={(event) => setWithReservation(event.currentTarget.checked)}*/}
          {/*/>*/}
        </div>
      </div>
      <div className="hidden lg:grid mt-4 grid-cols-12">
        <div className="col-span-12 mr-6 flex items-center justify-end gap-8 text-secondary">
          <span className="quicksand font-medium">M</span>
          <span className="quicksand font-medium">T</span>
          <span className="quicksand font-medium">W</span>
          <span className="quicksand font-medium">T</span>
          <span className="quicksand font-medium">F</span>
          {!hideWeekends && (
            <>
              <span className="quicksand font-medium">S</span>
              <span className="quicksand font-medium">S</span>
            </>
          )}
        </div>
      </div>

      {currentOffice && wlAllData ? (
        <>
          {sortedUsers.length > 0 &&
          browserWindowSize &&
          browserWindowSize.height ? (
            <ul>
              <ScrollArea
                style={{
                  height: browserWindowSize.height - 64 - 203 - 16 - 175
                }}
                mx="auto"
                offsetScrollbars
                className="h-full"
              >
                <>
                  {sortedUsers.map((user) => {
                    return (
                      <ListItemBeta2
                        week={week}
                        key={user.id}
                        user={user}
                        workingLocationData={wlAllData}
                        office={currentOffice}
                        withReservation={withReservation}
                      />
                    );
                  })}
                </>
                {workingLocationData.hasNextPage ? (
                  <li className="ml-1 border-b text-center">
                    <button
                      className="text-primary"
                      onClick={(ev) => {
                        ev.preventDefault();
                        workingLocationData.fetchNextPage();
                      }}
                    >
                      Load more
                    </button>
                  </li>
                ) : null}
              </ScrollArea>
            </ul>
          ) : (
            <div className="mx-auto flex max-w-3xl flex-col justify-center p-6 text-center">
              <h3 className="text-lg font-bold text-gray-800">
                Hmm. Looks like there aren't any team reservations yet this
                week.
              </h3>
              <p className="mt-2 text-gray-800">
                Once you're in a team and they are reserving workspaces in the
                office, you'll see their schedules here. Maybe try a different
                week by using the arrows above.
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="h-[163px] w-full animate-pulse rounded-md bg-gray-400" />
      )}
    </div>
  );
};

export default CheckedInTeamMembersBeta2;
