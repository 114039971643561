import React from 'react';
import { classNames } from '@/lib/classNames';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * Defines the color of the help text (the same as with Input, Select, etc.)
   */
  valid?: boolean;
}

export const styles = Object.freeze({
  base: 'text-sm ml-1',
  valid: 'text-green-600',
  invalid: 'text-red-600',
  pale: 'text-gray-500'
});

export const HelpText = React.forwardRef<HTMLSpanElement, Props>(
  function HelpText(props, ref) {
    const { children, valid, className, ...other } = props;

    const validationStyle = (valid: boolean | undefined): string => {
      switch (valid) {
        case true:
          return styles.valid;
        case false:
          return styles.invalid;
        default:
          return '';
      }
    };

    return (
      <span
        className={classNames(styles.base, validationStyle(valid), className)}
        ref={ref}
        {...other}
      >
        {children}
      </span>
    );
  }
);

export default HelpText;
