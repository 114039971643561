import Reserve, { ReserveProps } from '@/components/reserve/Reserve/Reserve';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { classNames } from '@/lib/classNames';
import { scheduleResourceStyling } from '@/lib/utils';
import { useWindowSize } from '@gettactic/helpers/src/hooks/useWindowSize';
import Image from 'next/image';

type Props = { reserveModal: boolean } & ReserveProps;

export function ReserveModal({
  reserveModal = false,
  resourceType = 'desk',
  embedded = false,
  start,
  time,
  duration,
  office,
  resource,
  floorId,
  schedule,
  onClose
}: Props) {
  const browserWindowSize = useWindowSize();
  const browserHeight = browserWindowSize.height;

  return (
    <>
      <Dialog open={reserveModal} onOpenChange={onClose}>
        <DialogContent className="max-w-[36rem] px-0 pt-0">
          <DialogHeader>
            <div
              className={`${
                scheduleResourceStyling(resourceType)?.style
              } h-[80px] w-full overflow-hidden rounded-t-lg p-4`}
            >
              <div className="flex h-full items-center justify-between">
                <h4 className="text-2xl font-bold text-tertiary">
                  {!schedule ? (
                    <>
                      {scheduleResourceStyling(resourceType)?.title} Reservation
                    </>
                  ) : (
                    <span>Edit Reservation</span>
                  )}
                </h4>
                <div>
                  <div className="flex h-full items-center justify-center">
                    <Image
                      src={scheduleResourceStyling(resourceType)?.image ?? ''}
                      width={140}
                      height={115}
                      alt={scheduleResourceStyling(resourceType)?.alt}
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogHeader>
          {reserveModal ? (
            <div
              style={{
                maxHeight: browserHeight ? `${browserHeight - 200}px` : '32rem'
              }}
              className={classNames(
                'max-w-[36rem] px-4 ',
                browserHeight && browserHeight < 700 ? 'overflow-y-auto' : ''
              )}
            >
              <Reserve
                resourceType={resourceType}
                embedded={embedded}
                start={start}
                time={time}
                duration={duration}
                office={office}
                resource={resource}
                floorId={floorId}
                schedule={schedule}
                onClose={onClose}
              />
            </div>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}
