import { IResourcePermissionWithResName } from '@/components/resources/tabs/access-restrictions/RestrictionsTab';
import { api } from '@/lib/api/api';
import { getDefaultsHoursForOffice } from '@/lib/featureFlags';
import { logError } from '@/lib/helpers/errors/logError';
import { TimeZone } from '@/lib/timezones';
import { EntityType } from '@/lib/types/EntityType';
import {
  IResource,
  ISchedule,
  ISlot,
  Office,
  ResGetResource,
  ResourceType,
  ResponseWithStatus,
  UserRoles,
  parseDateOnlyString,
  parseTimeOnly
} from '@gettactic/api';
import { type ClassValue, clsx } from 'clsx';
import {
  addMinutes,
  differenceInHours,
  format,
  isSameDay,
  isToday,
  isTomorrow,
  isWeekend,
  isYesterday,
  millisecondsToHours,
  parse,
  parseISO
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { twMerge } from 'tailwind-merge';

export const IMAGE_LAYOUTS = {
  '40x40_face': {
    cloudinary: 'upload/q_auto,f_auto,c_scale,w_80,h_80,g_face,c_thumb/r_max/',
    tacticCrop: { width: '80', height: '80' }
  },
  '125x125_face': {
    cloudinary:
      'upload/q_auto,f_auto,c_scale,w_250,h_250,g_face,c_thumb/r_max/',
    tacticCrop: { width: '250', height: '250' }
  },
  '300x300_face': {
    cloudinary:
      'upload/q_auto,f_auto,c_scale,w_600,h_600,g_face,c_thumb/r_max/',
    tacticCrop: { width: '600', height: '600' }
  },
  logo_max_width_300: {
    cloudinary: 'upload/q_auto,f_auto,w_300,h_300,c_limit/',
    tacticCrop: { width: '300', height: '300', fit: 'scale-down' }
  }
};

export function imageUrl(url: string, layout: keyof typeof IMAGE_LAYOUTS) {
  if (url.indexOf('https://res.cloudinary.com/') === 0) {
    return url.replace(/upload\/(.*?)\//i, IMAGE_LAYOUTS[layout].cloudinary);
  }
  if (url.indexOf('https://cdn.gettactic.com/image-crop/') === 0) {
    const parsed = new URL(url);
    parsed.searchParams.set(
      'width',
      IMAGE_LAYOUTS[layout].tacticCrop.width.toString()
    );
    parsed.searchParams.set(
      'height',
      IMAGE_LAYOUTS[layout].tacticCrop.height.toString()
    );
    return parsed.toString();
  }
  if (
    url.indexOf('https://cdn.gettactic.com/organization-logos/') === 0 ||
    url.indexOf('https://cdn.gettactic.com/profile-photos/') === 0
  ) {
    const params = new URLSearchParams({
      ...IMAGE_LAYOUTS[layout].tacticCrop,
      image: url
    });
    return `https://cdn.gettactic.com/image-crop/?${params.toString()}`;
  }
  return url;
}

export function dateParseISO(date: string) {
  return parseDateOnlyString(date);
}

export function formatStartEndHours(start: string, end: string) {
  const parsedStart = dateParseISO(start);
  const parsedEnd = dateParseISO(end);

  const timeDifference = Math.abs(parsedStart.getTime() - parsedEnd.getTime());
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

  if (hoursDifference >= 24) {
    return [
      format(parsedStart, 'MMMM do, hh:mm a'),
      format(parsedEnd, 'MMMM do, hh:mm a')
    ];
  }

  return [format(parsedStart, 'p'), format(parsedEnd, 'p')];
}

export function formatStartEndSlotHours({ start, end }: ISchedule) {
  return formatStartEndHours(start, end).join('-');
}

type GroupByReturn<T> = { [idx: string]: T[] };
export function groupBy<T extends object>(
  arr: T[],
  groupKey: keyof T | ((s: T) => string)
): GroupByReturn<T> {
  const groups: GroupByReturn<T> = {};
  const isCallable = groupKey instanceof Function;
  arr.forEach((el) => {
    const group = isCallable
      ? groupKey(el)
      : (el[groupKey] as unknown as string);
    groups[group] = groups[group] || [];
    groups[group].push(el);
  }, groups);
  return groups;
}

/**
 * Uses the Intl DateTime format to check the devices
 * currently set time zone. If unknown, will return
 * undefined.
 *
 * @returns Time Zone string in IANA format
 */
export function getDeviceTZ() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function convertTZ(date: Date, tzString = 'UTC') {
  return utcToZonedTime(date, tzString);
}

export function getMinDateAllowedToReserve(
  officeTz: string,
  dates: Date[] = []
) {
  const d = convertTZ(new Date(), officeTz);
  d.setHours(0, 0, 0, 0);
  return [...dates, d].reduce((a, b) => (a < b ? a : b));
}

/**
 * Important Note: Be careful when modifying this function. It's used for a lot of "availability" display logic.
 * It's extra confusing since the API is very literal about maximum amount of time in the future a user can reserve, to the hour
 * but administrators think in terms of number of days in the future. We use logic in components elsewhere to compensate.
 */
export function getMaxDateAllowedToReserve(
  officeTz: string,
  office: Office | undefined,
  resType: ResourceType
) {
  if (!office || !officeTz) {
    return null;
  }
  const maxMin =
    resType === 'meeting_room'
      ? office.meeting_room_reservation_period_min
      : office.workspace_reservation_period_min;
  if (maxMin === null) {
    return null;
  }
  const d = convertTZ(new Date(), officeTz);
  return addMinutes(d, maxMin);
}

export function isAllowedToReserve(
  day: Date,
  officeTimezone: string,
  office: Office | undefined,
  resType: ResourceType,
  roles: UserRoles[] = []
) {
  const max = getMaxDateAllowedToReserve(officeTimezone, office, resType);
  const min = getMinDateAllowedToReserve(officeTimezone);
  if (!roles.includes('owner') && day.getTime() < min.getTime()) {
    return false;
  }
  if (max && day.getTime() > max.getTime()) {
    return false;
  }
  return true;
}

export function toEntity(type: ResourceType): EntityType {
  switch (type) {
    case 'desk':
    case 'workspace':
      return 'workspace';
    case 'meeting_room':
      return 'meeting_room';
    case 'parking_lot':
    case 'parking_space':
      return 'parking';
    case 'area':
      return 'area';
    default:
      // biome-ignore lint/correctness/noSwitchDeclarations: <explanation>
      const exhaustiveCheck: string = type;
      throw new Error(`Unhandled resource type: ${exhaustiveCheck}`);
  }
}

/**
 * Return offset in hour between user's timezone
 * and param timezone
 *
 * @param timezone
 */
export function timezoneOffset(timezone: string) {
  const now = new Date();
  now.setHours(now.getHours(), 0, 0, 0);
  const nowTz = convertTZ(now, timezone);
  return millisecondsToHours(nowTz.getTime() - now.getTime());
}

/**
 * Returns true if timezone has a mistmatch with
 * user's timezone
 * @param timezone
 */
export function hasTimezoneMismatch(timezone: string) {
  return timezoneOffset(timezone) !== 0;
}

export function calculateHoursDifference(open: string, close: string) {
  const dateFromOpenString = parseTimeOnly(open);
  const dateFromCloseString = parseTimeOnly(close);
  return differenceInHours(dateFromCloseString, dateFromOpenString, {
    roundingMethod: 'ceil'
  });
}

export function getTimeForReservation(
  start: Date,
  time: Date | null,
  officeTz: string,
  officeId?: string
) {
  let validTime = time;
  if (!validTime) {
    const { startHour, startMinutes } = getDefaultsHoursForOffice(officeId);
    validTime = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      startHour,
      startMinutes,
      0
    );
  } else if (!isSameDay(start, validTime)) {
    validTime = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      validTime.getHours(),
      validTime.getMinutes(),
      validTime.getSeconds()
    );
  }
  return validTime;
}

/**
 * Parse a API formatted date in the form
 * yyyy-MM-dd
 * @param str
 */
export function parseApiDate(str: string): Date {
  return parseApiDatetime(`${str} 00:00:00`);
}

/**
 * Parse a API formatted datetime in the form
 * yyyy-MM-dd HH:mm:ss
 * @param str
 */
export function parseApiDatetime(str: string): Date {
  return parse(str, 'yyyy-MM-dd HH:mm:ss', new Date());
}

export function getPreviousMonday(from: Date = new Date()) {
  from.setDate(from.getDate() - ((from.getDay() + 6) % 7));
  return from;
}

/**
 * Returns true or false when passed a date string & office time zone
 * M - F = False, Saturday & Sunday = True.
 *
 */
export function hasWeekendDay(weekDatesISO: string[]): boolean {
  return !!weekDatesISO.find((x) => {
    const localDate = parseISO(x);
    return isWeekend(localDate);
  });
}

export function round(num: number, decimalPlaces = 2) {
  // biome-ignore lint/style/useExponentiationOperator: <explanation>
  const pow = Math.pow(10, decimalPlaces);
  return Math.round((num + Number.EPSILON) * pow) / pow;
}

export interface SelectOptionBase {
  label: string;
  value: string;
}

export function generateTimeZoneSelectOptions(timezones: TimeZone[]) {
  return timezones.map((timezone) => {
    return {
      label: timezone.label,
      value: timezone.id
    };
  });
}

export function findTimeZoneFromId(
  timezoneId: string,
  timezones: TimeZone[]
): TimeZone | undefined {
  return timezones.find((timezone) => timezone.id === timezoneId);
}

export function findTimeZoneSelectOption<T extends SelectOptionBase>(
  timezone: TimeZone,
  options: T[]
) {
  return options.find((option) => option.value === timezone.id);
}

export function convertUTCToLocalDate(date: Date | string) {
  const cDate = new Date(date);
  return new Date(
    cDate.getUTCFullYear(),
    cDate.getUTCMonth(),
    cDate.getUTCDate(),
    cDate.getUTCHours(),
    cDate.getUTCMinutes(),
    cDate.getUTCSeconds()
  );
}

export function areAll<T>(col: T[], what?: T): boolean {
  const is = col[0];
  if (col.findIndex((x) => x !== is) > -1) {
    return false;
  }
  return typeof what !== 'undefined' ? is === what : true;
}

export function replaceVarsBulkName(template: string, index: number): string {
  const now = new Date();
  const indexMatch = template.match(/({{number:(\d+)}})/);
  const currentDate = format(now, 'yyyy-MM-dd');
  const currentTime = format(now, 'HH:mm:ss');
  let newName: string;
  if (indexMatch?.[1] && indexMatch[2]) {
    newName = template.replaceAll(
      indexMatch[1],
      (index + Number.parseInt(indexMatch[2], 10)).toString()
    );
  } else {
    newName = template.replaceAll('{{number}}', (index + 1).toString());
  }
  newName = newName.replaceAll('{{date}}', currentDate);
  newName = newName.replaceAll('{{time}}', currentTime);
  return newName;
}

export const getDaySplit = (
  day: string
): { year: string; month: string; day: string } => {
  const daySplit = day.split('-');
  const year = daySplit[0];
  const month = daySplit[1];
  const dayValue = daySplit[2];

  return { year: year, month: month, day: dayValue };
};

export const mobileParseApiDate = (day: string): Date => {
  const daySplit = getDaySplit(day);

  const timeString = day.split('T')[1].slice(0, -1);
  const timeSplit = timeString.split(':');
  const hours = Number.parseInt(timeSplit[0]);
  const minutes = Number.parseInt(timeSplit[1]);
  const seconds = Number.parseInt(timeSplit[2]);

  return new Date(
    Number.parseInt(daySplit.year),
    Number.parseInt(daySplit.month) - 1,
    Number.parseInt(daySplit.day),
    hours,
    minutes,
    seconds
  );
};

/**
 * Creates a human friendly date string in relation to the current date. For example, if the date is today, it will return "Today".
 */
export const getDistanceToNow = (_date: string) => {
  const date = mobileParseApiDate(_date);

  // Requires explicit checks since date-fns will return "in 20 minutes", "12 hours ago", etc.
  if (isToday(date)) {
    return 'Today';
  }
  if (isTomorrow(date)) {
    return 'Tomorrow';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }

  // Outside of the above exceptions, show date formatted as "Wednesday, Sep. 01"
  return format(date, 'eeee, MMM. dd');
};

export function primaryScheduleResource(slots: ISlot[]): IResource {
  // Looks for resources by priority in the order of the array
  const resourceTypeOrder: ResourceType[] = [
    'desk',
    'workspace',
    'meeting_room',
    'parking_space',
    'parking_lot'
  ];

  for (const resourceType of resourceTypeOrder) {
    const slot = slots.find((slot) => slot.resource.type === resourceType);
    if (slot) {
      return slot.resource;
    }
  }

  return slots[0].resource;
}

/**
 * More centralized location to store resource images and styles so that they can be easily updated in the future.
 */
export function   scheduleResourceStyling(resourceType: ResourceType) {
  const resourceImages: Partial<
    Record<
      ResourceType,
      {
        title: string;
        image: string;
        alt: string;
        style: string;
      }
    >
  > = {
    desk: {
      title: 'Desk',
      image: 'https://cdn.gettactic.com/website/images/desk.webp',
      alt: 'Desk with monitor and small plant',
      style: 'bg-primary-lighter'
    },
    workspace: {
      title: 'Desk',
      image: 'https://cdn.gettactic.com/website/images/desk.webp',
      alt: 'Desk with monitor and small plant',
      style: 'bg-primary-lighter'
    },
    meeting_room: {
      title: 'Room',
      image: 'https://cdn.gettactic.com/website/images/meeting_room.webp',
      alt: 'Meeting room with projector screen and chairs',
      style: 'bg-primary-lighter'
    },
    parking_lot: {
      title: 'Parking',
      image: 'https://cdn.gettactic.com/website/images/parking.webp',
      alt: 'Car parked in a commercial garage',
      style: 'bg-gray-100'
    },
    parking_space: {
      title: 'Parking',
      image: 'https://cdn.gettactic.com/website/images/parking.webp',
      alt: 'Car parked in a commercial garage',
      style: 'bg-gray-100'
    }
  };
  return resourceImages[resourceType] ?? resourceImages.desk;
}

/**
 * Gets the resource chain for the resourceId provided i.e. the parent_id chain
 *
 * TODO: Mobile code that was borrowed since we don't have office_name, floor_name, etc. on the elements
 * of the scheduleMeData query. These resourceChain functions should be removed once we have the data.
 */
export const getResourceChain = async (parentId: string | null = null) => {
  const chain = [];
  let nextId: string | null = parentId;
  while (nextId !== null) {
    const result: ResponseWithStatus<ResGetResource> =
      await api.client.organizations.resourceGet(nextId);
    if (!result || !result.result) {
      return undefined;
    }
    chain.push(result.result);
    nextId = result.result.parent_id;
  }
  return chain;
};

export async function getIntercomHash(slug: string): Promise<{ hash: string }> {
  const res = await fetch(`/api/intercom?slug=${encodeURIComponent(slug)}`);
  const hash = await res.json();
  return hash.hash;
}

export function isWorkingLocationOffice(location: string | null) {
  return location?.startsWith('offi_');
}

export function getResourceTypeName(resource: IResource) {
  if (resource.type === 'desk') {
    return 'Workspace';
  }
  if (resource.type === 'workspace') {
    return 'Workspace';
  }
  if (resource.type === 'meeting_room') {
    return 'Room';
  }
  if (resource.type === 'parking_lot') {
    return 'Parking';
  }
  if (resource.type === 'parking_space') {
    return 'Parking Space';
  }
  return 'Desk';
}

export function sortPermissionsArray(
  permissions: IResourcePermissionWithResName[]
) {
  return permissions.sort((a, b) => {
    if (a.since && b.since) {
      return new Date(a.since) > new Date(b.since) ? 1 : -1;
    }
    if (a.since) {
      return -1;
    }
    if (b.since) {
      return 1;
    }
    if (a.until && b.until) {
      return new Date(a.until) > new Date(b.until) ? 1 : -1;
    }
    if (a.until) {
      return -1;
    }
    if (b.until) {
      return 1;
    }
    return a.id > b.id ? 1 : -1;
  });
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getNewUrl(organizationSlug: string, actualSlug?: string) {
  const currentHostName = window.location.hostname;
  const newHostName = currentHostName.replace(
    actualSlug ?? '',
    organizationSlug
  );
  // this way we keep same protocol, domain, path, params, etc
  const newUrl = window.location.href.replace(currentHostName, newHostName);
  return newUrl;
}

export function formatDisplayName(name: string) {
  return name.trim().replace(/\.$/, '');
}

const LAST_FLOOR_STORAGE_KEY = 'lastFloorId';
export function getStorageFloor() {
  try {
    return localStorage.getItem(LAST_FLOOR_STORAGE_KEY);
  } catch (e) {
    logError(e);
  }
  return '';
}
export function setStorageFloor(floor: string | null | undefined) {
  try {
    localStorage.setItem(LAST_FLOOR_STORAGE_KEY, floor ?? '');
  } catch (e) {
    logError('Storage', e);
  }
}
