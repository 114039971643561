import useUserList from '@/hooks/useUserList';
import { api } from '@/lib/api/api';
import { isValidEmail } from '@/lib/validation/email';
import { IGenericOption } from '@gettactic/api';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Props, SelectInstance } from 'react-select';
import AsyncSelect from 'react-select/async-creatable';

// we omit isMulti, and we define it again
// because is not well-defined in the base type
type PropsSelectUsers = Omit<
  Props<SelectInstance<{ label: string; value: string }>>,
  'onChange' | 'isMulti' | 'defaultValue'
> & {
  classNamePrefix: string | undefined;
  defaultValue?: string[] | undefined;
  setInitial?: (vals: IGenericOption[]) => void;
  isMulti?: boolean;
  filterValues?: (
    value: IGenericOption,
    index: number,
    array: IGenericOption[]
  ) => boolean;
  forceInclude?: string[];
  onChange: (arg: IGenericOption[] | null) => void;
  readonly?: boolean;
  managed: boolean;
};

export default function SelectUsersAsync({
  defaultValue,
  setInitial,
  filterValues,
  forceInclude = undefined,
  isMulti = true,
  readonly = false,
  managed = false,
  ...rest
}: PropsSelectUsers) {
  const initLoading = useRef(true);
  const initFetching = useRef(false);
  const [initialList, setInitialList] = useState<IGenericOption[]>([]);
  const stableDefValue = useMemo(() => defaultValue, [defaultValue?.join('')]);

  useEffect(() => {
    // TODO: Refactor to use react-query -- with a proper backend endpoint to ask all the ids
    const lookupUsers = [...(stableDefValue ?? []), ...(forceInclude ?? [])];
    const results = api.client.organizations.usersById(lookupUsers);
    results.then((res) => {
      initFetching.current = true;

      setInitialList(
        res
          .filter((x) => !!x)
          .map((user) => ({ value: user?.id ?? '', label: user?.name ?? '' }))
      );
    });
  }, [stableDefValue, forceInclude]);
  useEffect(() => {
    if (initLoading.current && initFetching.current) {
      const filterInitial = filterValues
        ? initialList.filter(filterValues)
        : initialList;
      if (setInitial) {
        const newInitial = filterInitial.filter((x) =>
          defaultValue?.includes(x.value)
        );
        setInitial(newInitial);
      }

      initLoading.current = false;
    }
  }, [initialList]);
  const [query, setQuery] = useState('');
  const userList = useUserList(query, null, null, managed);
  const loadOptionsRef = useRef(null);
  useEffect(() => {
    if (loadOptionsRef.current && userList.data?.options) {
      const options = filterValues
        ? userList.data.options.filter(filterValues)
        : userList.data.options;
      //@ts-expect-error emilio fix -- this whole component should be deprecated to use the new mantine select + react-query
      loadOptionsRef.current(options);
    }
  }, [userList, loadOptionsRef.current, filterValues]);
  const loadOptions = useCallback((inputValue: string, callback: any) => {
    loadOptionsRef.current = callback;
    setQuery(inputValue);
  }, []);

  return (
    <AsyncSelect
      {...rest}
      key={`${initialList.map((x) => x.value).join('')}`}
      loadOptions={loadOptions}
      //@ts-expect-error @todo We should fix this later
      defaultValue={initialList.filter((x) => defaultValue?.includes(x.value))}
      defaultOptions
      isMulti={isMulti}
      isSearchable={!readonly}
      menuIsOpen={readonly ? false : undefined}
      allowCreateWhileLoading={false}
      formatCreateLabel={(inputValue) => `Add: ${inputValue}`}
      isValidNewOption={(inputValue) =>
        inputValue.length > 0 && isValidEmail(inputValue)
      }
      noOptionsMessage={() =>
        'Try a different search term or add users by email'
      }
    />
  );
}
