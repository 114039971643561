import { RRule } from "rrule";
import { toDate } from "date-fns-tz";
import { startOfMonth } from "date-fns";

export function getNthOccurrenceOfDayInMonth(date: Date): number {
  if (!date || Number.isNaN(new Date(date).getTime())) {
    return 0;
  }

  const currentDate = toDate(date);
  const dayOfMonth = currentDate.getDate();
  const targetDay = currentDate.getDay();

  const startOfMonthDate = startOfMonth(currentDate);

  // Example: Find the first Sunday in February 2025 (Month starts on Saturday)
  // targetDay - startOfMonthDate.getDay() = 0 - 6 = -6
  // Add 7 to avoid negatives: (-6 + 7) = 1
  // Modulo to avoid leaving 0-6 range: 1 % 7 = 1
  // Result: The first Sunday is on the 2nd day of the month (1 + 1 = 2).
  const firstTargetDay =
    startOfMonthDate.getDate() +
    ((targetDay - startOfMonthDate.getDay() + 7) % 7);

  return Math.ceil((dayOfMonth - firstTargetDay + 1) / 7);
}

export const RRULE_WEEKDAYS = [
  RRule.SU,
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
];
function getDayWeekNumber(date: Date, nth: number) {
  const weekDays = RRULE_WEEKDAYS[date.getDay()];
  return weekDays.nth(nth);
}

export const byWeekDay = (date: Date) => {
  return [getDayWeekNumber(date, getNthOccurrenceOfDayInMonth(date))];
};
