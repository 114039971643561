import { Button, Modal, Responsive } from '@/components/basic';
import {
  WorkingLocationSelector,
  getLocationIdentifier
} from '@/components/dashboard/WorkingLocationSelector';
import {
  USER_PHOTO_SIZES,
  UserPhotoCard
} from '@/components/partial/Cards/UserPhotoCard';
import TacticPhotoUpload, {
  PhotoUploadType
} from '@/components/upload/TacticPhotoUpload';
import FloorFilterDropdown from '@/components/util/FloorFilterDropdown';
import { LoadingSpinner } from '@/components/util/LoadingSpinner';
import OfficeFilterDropdown from '@/components/util/OfficeFilterDropdown';
import TimeZonePicker from '@/components/util/TimeZoneSelect';
import useAlreadySeen from '@/hooks/useAlreadySeen';
import { useFloors } from '@/hooks/useAreas';
import { api } from '@/lib/api/api';
import { useAuthenticated } from '@/lib/api/appUser';
import { classNames } from '@/lib/classNames';
import { isWorkingLocationsEnabled } from '@/lib/featureFlags';
import { isWorkingLocationOffice, setStorageFloor } from '@/lib/utils';
import {
  EditProfileFormType,
  PresenceTypeByDay,
  dateFormatOptions,
  schemaEditProfile
} from '@/lib/validation/profile';
import {
  ALL_WEEK_DAYS,
  AuthenticatedUser,
  IWorkingLocationPresenceType,
  IWorkingLocations,
  WL_PRESENCE_OFFICE,
  WeekDayNames
} from '@gettactic/api';
import { CheckIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {getObjectKeys} from "@/lib/helpers/objects/getObjectKeys";

function hasMissingWorkingLocation(
  workingLocations: IWorkingLocations
): boolean {
  const defaultWorkingLocations = workingLocations.days;
  for (const date in defaultWorkingLocations) {
    const userDefaults = defaultWorkingLocations[date];
    for (const userDefault of userDefaults) {
      if (userDefault.location === null) {
        return true;
      }
    }
  }
  return false;
}

export const NewUserWelcomeModalBeta2 = ({
  authenticatedUser,
  workingLocationProfileData
}: {
  authenticatedUser: AuthenticatedUser;
  workingLocationProfileData: IWorkingLocations;
}): JSX.Element => {
  const { setUserContext } = useAuthenticated();
  const queryClient = useQueryClient();
  const alreadySeen = useAlreadySeen('NewUserWelcomeModalWithWl');
  const userData = authenticatedUser,
    officeData = authenticatedUser.offices;
  const [isWelcomeModalOpen, setNewUserWelcomeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<EditProfileFormType>({
    // TODO: Replace with Zod
    // resolver: yupResolver(schemaEditProfile)
  });
  const photoUrl = userData.user?.profile_photo_url ?? null;

  const steps = {
    profilePic: photoUrl !== null,
    profileInfo:
      userData.user?.time_zone !== 'UTC' && userData.user?.time_zone !== null,
    workingLocations: Object.keys(workingLocationProfileData.days).every(
      (x) =>
        workingLocationProfileData.days[x].length &&
        workingLocationProfileData.days[x].every((x) => x.location !== null)
    )
  };

  const firstIncomplete = getObjectKeys(steps).find((x) => !steps[x]);
  const needsCompleteProfile = !!firstIncomplete;

  const [selectedOffice, setSelectedOffice] = useState(
    officeData?.byId[officeData.officeId ?? 0] ?? null
  );
  const [filterArea, setFilterArea] = useState<string | null>(
    authenticatedUser.defaultAreaId
  );
  const { floors } = useFloors(selectedOffice?.id ?? '');

  useEffect(() => {
    if (
      workingLocationProfileData &&
      workingLocationProfileData.days &&
      hasMissingWorkingLocation(workingLocationProfileData)
    ) {
      setNewUserWelcomeModal(true);
    }
    if (!needsCompleteProfile || alreadySeen) {
      return;
    }
    setNewUserWelcomeModal(true);
  }, [needsCompleteProfile, alreadySeen, workingLocationProfileData.days]);

  function closeNewUserWelcomeModal(): void {
    setNewUserWelcomeModal(false);
  }

  const submitMutation = useMutation(
    async ({
      profile,
      workingLocations
    }: { profile: any; workingLocations: PresenceTypeByDay }) => {
      setLoading(true);
      const resProfile = await api.client.organizations.updateMe(profile);
      if (resProfile.ok) {
        setStorageFloor(profile.default_area_id);
        if (profile.default_office_id) {
          setUserContext({
            type: 'updateCurrentOffice',
            payload: profile.default_office_id
          });
        }
      }
      const workingLocationsPayload: {
        [dayIdx in WeekDayNames]?:
          | {
              location: IWorkingLocationPresenceType;
              office_id?: string | undefined;
            }
          | undefined;
      } = {};
      let resWorkingLocations = null;
      let hasAtLeastOneDay = false;
      if (isWorkingLocationsEnabled(authenticatedUser)) {
        Object.entries(workingLocations).forEach(
          ([day, workingLocationId], dayNumber) => {
            const isOffice = isWorkingLocationOffice(workingLocationId);
            const location = isOffice
              ? {
                  office_id: workingLocationId,
                  location: WL_PRESENCE_OFFICE as IWorkingLocationPresenceType
                }
              : workingLocationId
                ? {
                    location: workingLocationId
                  }
                : undefined;
            //@ts-ignore
            workingLocationsPayload[day] = location;
            if (location) {
              hasAtLeastOneDay = true;
            }
          }
        );
        if (hasAtLeastOneDay) {
          resWorkingLocations =
            await api.client.schedules.updateWorkingLocationDefaults(
              workingLocationsPayload
            );
        }
      }

      await queryClient.invalidateQueries(['working-locations-profile']);
      await queryClient.invalidateQueries(['schedules']);
      return { resProfile, resWorkingLocations };
    },
    {
      onSuccess: () => {
        setLoading(false);
        queryClient.invalidateQueries(['organizations.getOrgMe']);
        queryClient.invalidateQueries(['organizations.offices']);
        toast.success('Changes saved');
        closeNewUserWelcomeModal();
      }
    }
  );

  const onProfileSubmit = async (data: EditProfileFormType) => {
    const defaultOfficeId = selectedOffice?.id
      ? selectedOffice?.id
      : officeData !== null && officeData.officeId !== null
        ? officeData.officeId
        : null;
    const request = {
      name: data.name,
      phone: data.phone,
      location: data.location,
      about: data.about,
      time_zone: data.time_zone,
      title: data.title,
      date_format: data.date_format.value,
      profile_photo_url: photoUrl,
      default_office_id: defaultOfficeId,
      default_presence_type:
        authenticatedUser.user?.organization_user.default_presence_type ??
        'in_person', // TODO: Add default presence drop down to this modal
      default_area_id: filterArea
    };
    submitMutation.mutate({
      profile: request,
      workingLocations: data.default_presence_type_by_day
    });
  };
  const dayKeys = Object.keys(workingLocationProfileData.days ?? {});

  return (
    <Modal
      title="Let's complete your profile!"
      opened={isWelcomeModalOpen}
      onClose={closeNewUserWelcomeModal}
      size="max-w-[36rem]"
    >
      <form onSubmit={handleSubmit(onProfileSubmit)}>
        <div>
          <div className="grid max-w-4xl grid-cols-1 place-items-center gap-x-8 ">
            <div className="w-[500px]">
              <Accordion defaultValue={firstIncomplete ?? 'profilePic'}>
                <Accordion.Item value="profilePic">
                  <Accordion.Control>
                    <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900">
                      <span>Upload a Profile Picture</span>
                      {steps.profilePic ? (
                        <CheckIcon className="ml-2 h-5 w-5 text-green-500" />
                      ) : null}
                    </div>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <div className="col-span-12">
                      <div className="relative flex justify-center overflow-hidden rounded-full">
                        <TacticPhotoUpload
                          aspect={1}
                          resizeImage={{ w: 300, h: 300 }}
                          type={PhotoUploadType.UserProfile}
                        >
                          {({ loading, isDragActive }) => (
                            <>
                              <div className="mx-auto text-center">
                                <UserPhotoCard
                                  className="hidden sm:block"
                                  size={USER_PHOTO_SIZES.ROUNDED_40_40}
                                  profile_photo_url={photoUrl}
                                  name={userData.user?.name}
                                />
                                <UserPhotoCard
                                  className="block sm:hidden"
                                  size={USER_PHOTO_SIZES.ROUNDED_28_28}
                                  profile_photo_url={photoUrl}
                                  name={userData.user?.name}
                                />
                              </div>

                              {loading ? (
                                <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-center font-medium text-white opacity-80">
                                  <LoadingSpinner />
                                </div>
                              ) : (
                                <div>
                                  <div
                                    className={classNames(
                                      isDragActive && ' opacity-100 ',
                                      !isDragActive && ' opacity-0 ',
                                      'absolute inset-0 hidden h-full w-full items-center justify-center bg-black  bg-opacity-75 font-medium text-white focus-within:opacity-100  hover:opacity-100 sm:flex'
                                    )}
                                  >
                                    <span>
                                      {isDragActive ? 'Drop here' : 'Change'}
                                    </span>
                                    <span className="sr-only"> user photo</span>
                                    <div className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0" />
                                  </div>
                                  <div className="text-trueGray-500 block font-medium sm:hidden">
                                    Upload photo
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </TacticPhotoUpload>
                      </div>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="profileInfo">
                  <Accordion.Control className="text-lg font-medium leading-6 text-gray-900">
                    <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900">
                      <span>Complete your Profile</span>
                      {steps.profileInfo ? (
                        <CheckIcon className="ml-2 h-5 w-5 text-green-500" />
                      ) : null}
                    </div>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <div className="grid w-full grid-cols-12 gap-4">
                      <Controller
                        control={control}
                        name="name"
                        defaultValue={userData.user?.name}
                        render={({ field }) => (
                          <Responsive.Label
                            filled={field.value.length > 0}
                            className="col-span-12"
                            text="Name"
                            htmlFor="name"
                          >
                            <Responsive.Input
                              type="text"
                              id="name"
                              autoComplete="name"
                              {...field}
                            />
                          </Responsive.Label>
                        )}
                      />
                      <Controller
                        control={control}
                        name="title"
                        defaultValue={
                          userData.user?.organization_user?.title ?? ''
                        }
                        render={({ field }) => (
                          <Responsive.Label
                            filled={field.value.length > 0}
                            className="col-span-12"
                            text="Title"
                            htmlFor="title"
                          >
                            <Responsive.Input
                              type="text"
                              id="title"
                              {...field}
                            />
                          </Responsive.Label>
                        )}
                      />
                      <Controller
                        name="date_format"
                        control={control}
                        defaultValue={dateFormatOptions.find(
                          (x) =>
                            x.value ===
                            userData.user?.organization_user?.date_format
                        )}
                        render={({ field }) => (
                          <Responsive.Label
                            className="col-span-12 w-full"
                            text="Date Format"
                            filled={true}
                          >
                            <Responsive.Select
                              options={dateFormatOptions}
                              {...field}
                            />
                          </Responsive.Label>
                        )}
                      />

                      {officeData && officeData.offices.length > 0 && (
                        <div className="col-span-12 w-full">
                          <label
                            htmlFor="location"
                            className="block font-medium text-gray-800"
                          >
                            Default Office
                          </label>
                          <OfficeFilterDropdown
                            className="grow"
                            setOfficeId={(id) => {
                              setSelectedOffice(
                                officeData?.offices?.find((x) => x.id === id) ??
                                  selectedOffice
                              );
                            }}
                            officeId={selectedOffice?.id ?? null}
                            offices={officeData?.offices ?? []}
                          />
                        </div>
                      )}

                      {floors && floors.length > 0 && (
                        <div className="col-span-12 w-full">
                          <label
                            htmlFor="floor"
                            className="block font-medium text-gray-800"
                          >
                            Default Floor
                          </label>
                          <FloorFilterDropdown
                            floors={floors ?? []}
                            floor={filterArea}
                            setFloor={setFilterArea}
                          />
                        </div>
                      )}

                      <Controller
                        name="time_zone"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={authenticatedUser.user?.time_zone}
                        render={({ field }) => (
                          <Responsive.Label
                            filled={!!field.value}
                            className="col-span-12"
                            text="Time Zone"
                            id="label-time-zone"
                          >
                            <TimeZonePicker
                              {...{
                                ...field,
                                'aria-labelledby': 'label-time-zone'
                              }}
                            />
                          </Responsive.Label>
                        )}
                      />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="workingLocations">
                  <Accordion.Control className="text-lg font-medium leading-6 text-gray-900">
                    <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900">
                      <span>Configure your Working Locations</span>
                      {steps.workingLocations ? (
                        <CheckIcon className="ml-2 h-5 w-5 text-green-500" />
                      ) : null}
                    </div>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <div className="col-span-12 w-full">
                      <div>
                        <p className=" text-gray-900">
                          Set your default working locations for each day.
                        </p>
                      </div>
                      <div className="mt-4 flex w-full flex-col space-y-2 ">
                        {ALL_WEEK_DAYS.map((dayName, index) => {
                          const dayDateStr = dayKeys[index];
                          const days = workingLocationProfileData.days ?? {};
                          const defaultValue = getLocationIdentifier(
                            days[dayDateStr] ? days[dayDateStr][0] : null
                          );
                          return (
                            <div
                              className="flex w-full items-center justify-between space-x-10"
                              key={dayName}
                            >
                              <div className="min-w-[85px] capitalize">
                                {dayName}
                              </div>
                              <div className="w-full grow">
                                <Controller
                                  name={`default_presence_type_by_day.${dayName}`}
                                  control={control}
                                  defaultValue={defaultValue}
                                  render={({ field: { onChange, value } }) => (
                                    <WorkingLocationSelector
                                      officesOnly={true}
                                      type="select"
                                      setValue={(val) => {
                                        onChange(val);
                                      }}
                                      value={value ?? ''}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>

              <div className="col-span-12 sm:mt-2">
                <Button
                  className="w-full rounded-3xl sm:rounded-md"
                  layout="squared"
                  variant="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <LoadingSpinner color="text-white" />
                  ) : (
                    <span>Save and Explore</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default NewUserWelcomeModalBeta2;
