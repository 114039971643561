import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { useOneSchedule } from '@/hooks/useOneSchedule';
import { classNames } from '@/lib/classNames';
import { scheduleResourceStyling } from '@/lib/utils';
import { ISchedule } from '@gettactic/api';
import { useWindowSize } from '@gettactic/helpers/src/hooks/useWindowSize';
import Image from 'next/image';
import ReserveEdit from './ReserveEdit/ReserveEdit';

type Props = {
  editModal: boolean;
  toggleModal: () => void;
  schedule: ISchedule;
  canRespondCancel?: boolean;
  respondCancel?: () => void;
  shouldFetchSchedule?: boolean;
};

function ReserveEditModal({
  editModal = false,
  toggleModal,
  schedule,
  canRespondCancel,
  respondCancel,
  shouldFetchSchedule = false
}: Props) {
  const browserWindowSize = useWindowSize();
  const browserHeight = browserWindowSize.height;

  const { data: updatedScheduleData, isLoading: scheduleLoading } =
    useOneSchedule({
      id: schedule.id,
      options: {
        enabled: !!schedule && shouldFetchSchedule
      },
      defaultSchedule: schedule
    });

  const scheduleToUse = shouldFetchSchedule ? updatedScheduleData : schedule;

  return (
    <>
      <Dialog open={editModal} onOpenChange={toggleModal}>
        <DialogContent
          className="max-w-[32rem] px-0 pt-0"
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <DialogHeader>
            <div
              className={`${
                scheduleResourceStyling(schedule?.resource?.type ?? 'desk')
                  ?.style
              } h-[80px] w-full overflow-hidden rounded-t-lg p-4`}
            >
              <div className="flex h-full items-center justify-between">
                <h4 className="text-2xl font-bold text-tertiary">
                  Edit Reservation
                </h4>
                <div>
                  <div className="flex h-full items-center justify-center">
                    <Image
                      src={
                        scheduleResourceStyling(
                          schedule?.resource?.type ?? 'desk'
                        )?.image ?? ''
                      }
                      width={140}
                      height={115}
                      alt={
                        scheduleResourceStyling(
                          schedule?.resource?.type ?? 'desk'
                        )?.alt
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogHeader>
          {scheduleToUse ? (
            <div
              style={{
                maxHeight: browserHeight ? `${browserHeight - 200}px` : '32rem'
              }}
              className={classNames(
                `max-w-[32rem] px-4 `,
                browserHeight && browserHeight < 700 ? 'overflow-y-auto' : ''
              )}
            >
              <ReserveEdit
                schedule={scheduleToUse}
                onClose={toggleModal}
                canRespondCancel={canRespondCancel}
                respondCancel={respondCancel}
                isLoading={shouldFetchSchedule && scheduleLoading}
              />
            </div>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ReserveEditModal;
