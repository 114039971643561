import {
  ALL_WEEK_DAYS,
  DATE_FORMAT_STYLE_DMY,
  DATE_FORMAT_STYLE_MDY,
  DATE_FORMAT_STYLE_YMD,
  DateFormatStyle,
  IWorkingLocationPresenceType,
  PresenceType,
  WeekDayNames
} from '@gettactic/api';
import { format } from 'date-fns';
import * as yup from 'yup';

const shape = {
  name: yup
    .string()
    .required('A profile name is required')
    .min(1, 'Name too short. Requires a minimum of 1 characters.')
    .max(255, 'Name too long. Allows a maximum of 255 characters.'),
  about: yup
    .string()
    .optional()
    .max(255, 'Field is too long. Allows a maximum of 255 characters.'),
  phone: yup
    .string()
    .optional()
    .max(255, 'Field is too long. Allows a maximum of 255 characters.'),
  title: yup
    .string()
    .optional()
    .max(255, 'Field is too long. Allows a maximum of 255 characters.'),
  birthdate: yup.string().optional(),
  location: yup.string().optional(),
  time_zone: yup.string().optional(),
  default_office_id: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() }),
  default_floor_id: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() }),
  default_presence_type: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
};

export type PresenceTypeByDay = {
  [idx in WeekDayNames]: IWorkingLocationPresenceType | string;
};

export type EditProfileFormType = {
  name: string;
  about: string;
  phone: string;
  title: string;
  birthdate: Date;
  location: string;
  time_zone: string;
  default_office_id: string | null;
  default_floor_id: { label: string; value: string } | null;
  default_presence_type: {
    label: string;
    value: PresenceType | undefined;
  } | null;
  default_presence_type_by_day: PresenceTypeByDay;
  date_format: { label: JSX.Element; value: DateFormatStyle };
};

export const shapeDefaultPresenceTypeByDay = {};

ALL_WEEK_DAYS.forEach(
  //@ts-expect-error shapeDefaultPresenceTypeByDay not typed
  (day) => (shapeDefaultPresenceTypeByDay[day] = yup.string().optional())
);

export const schemaEditProfile = yup
  .object()
  .shape({ ...shape, days: yup.object({ ...shapeDefaultPresenceTypeByDay }) });

export const dateFormatOptions: readonly {
  label: JSX.Element;
  value: DateFormatStyle;
}[] = Object.freeze([
  {
    label: (
      <span className="flex items-center space-x-4 uppercase">
        <span>{format(new Date(), 'MM/dd/yyyy')}</span>{' '}
        <span className="text-sm text-gray-500">MM/dd/yyyy</span>
      </span>
    ),
    value: DATE_FORMAT_STYLE_MDY
  },
  {
    label: (
      <span className="flex items-center space-x-4 uppercase">
        <span>{format(new Date(), 'dd/MM/yyyy')}</span>{' '}
        <span className="text-sm text-gray-500">dd/MM/yyyy</span>
      </span>
    ),
    value: DATE_FORMAT_STYLE_DMY
  },
  {
    label: (
      <span className="flex space-x-4 uppercase">
        <span>{format(new Date(), 'yyyy-MM-dd')}</span>{' '}
        <span className="text-sm text-gray-500">yyyy-MM-dd</span>
      </span>
    ),
    value: DATE_FORMAT_STYLE_YMD
  }
]);
